import { combineReducers } from 'redux';
import { ActionTypes } from 'utils/constants.jsx';


function getWebPhoneSetting(state = {}, action) {
    switch (action.type) {
        case ActionTypes.STORED_WEBPHONE_SETTING: {
            return action.data;
        }
        default:
            return state;
    }
}

function isWebphoneEnabled(state = false, action) {
    switch (action.type) {
        case ActionTypes.STORE_WEBPHONE_STATUS: {
            return action.data;
        }
        default:
            return state;
    }
}

function isGroupOrConferenceCallDragging(state = false, action) {
    switch (action.type) {
        case ActionTypes.GROUP_OR_CONFERENCE_CALL_DRAGGING: {
            return action.data;
        }
        default:
            return state;
    }
}

function username(state = "", action) {
    switch (action.type) {
        case ActionTypes.STORE_WEBPHONE_USERNAME: {
            return action.data;
        }
        default:
          return state;
    }
}

function CallerPrepend(state = "", action) {
    switch (action.type) {
        case ActionTypes.STORE_CALLER_PREFIX: {
            return action.data;
        }
        default:
          return state;
    }
}


export default combineReducers({
    getWebPhoneSetting,
    isWebphoneEnabled,
    isGroupOrConferenceCallDragging,
    username,
    CallerPrepend,
});
